<template>
  <app-side-menu 
    :min-width="18"
    :fixed-menu="false">
    <aside 
      class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Item No: Q{{ entityName | pad(3) }}
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <router-link 
            :to="{ name: routeTypes.QuickItemDetail.name, query: $route.query }"
            active-class="is-active"
            exact>
            <span 
              :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.detailGroup.$error }"
              :data-badge="$v.detailGroup.$error ? '' : null">
              Detail Info
            </span>
          </router-link>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import QuickItemRoutes from './route-types'
import { NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'QuickitemSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  mixins: [NumberFiltersMixin],
  props: {
    entityName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return QuickItemRoutes
    }
  },
  mounted() {},
  methods: {}
}
</script>
