<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Detail Info</p>
            </div>
          </div>

          <div class="field">
            <label class="label">Item No.</label>
            <span>Q{{ value.lineNumber | pad(3) }}</span>
          </div>

          <div class="field">
            <label class="label">Description</label>
            <div class="control desc-width">
              <textarea class="textarea"
                v-focus-inserted
                placeholder="Item Description"
                rows="3"
                cols="10"
                v-model="value.itemDesc"
                :class="{ 'is-danger' : $v.entity.itemDesc.$error }" />
              <span class="help is-danger"
                v-if="$v.entity && !$v.entity.itemDesc.required">Description is required</span>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field is-narrow">
                <label class="label">Quantity</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    :min="1"
                    :precision="0"
                    v-model.number="value.quantity" />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field is-narrow">
                <label class="label">Price</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    :min="0.00"
                    :precision="2"
                    v-model.number="value.price" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="$user.hasDelete($route.meta.id)"
            class="is-divider" />
          <div v-if="$user.hasDelete($route.meta.id)"
            class="field">
            <div class="buttons has-addons is-centered">
              <span class="button"
                :class="{ 'is-success is-selected' : !value.deleted }"
                @click="toggleActive(false)">
                <span class="icon is-small">
                  <i class="mdi mdi-check" />
                </span>
                <span>Active</span>
              </span>
              <span class="button"
                :class="{ 'is-danger is-selected' : value.deleted }"
                @click="toggleActive(true)">
                <span class="icon is-small">
                  <i class="mdi mdi-close" />
                </span>
                <span>In-Active</span>
              </span>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
// import QuickItemService from './QuickItemService'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import StoreMixin from './storeMixin'
import VueNumeric from '@/components/VueNumeric'
import { FocusInserted } from '@/components/directives'

export default {
  name: 'QuickitemDetail',
  inject: ['$vv'],
  components: {
    // QuickItemService,
    VueNumeric
  },
  directives: {
    FocusInserted
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, StoreMixin],
  props: {
    value: null,
    isNew: Boolean
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    //   this.$eventHub.$on(EventHubTypes.EntitySaved, data => {
    //     this.onEntitySaved(data)
    //   })
    //  this.$eventHub.$on(EventHubTypes.EntityReload, () => {
    //   this.onReload()
    //  })
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    toggleActive(deleted) {
      this.value.deleted = deleted
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.desc-width {
  width: 50%;
}
</style>
